import { Box, Button, chakra, StackProps, VStack } from "@chakra-ui/react"
import { useRouter } from "next/router"
import React from "react"

export type ErrorDisplayProps = {
  errorDetail?: React.ReactNode
  displayReloadButton?: boolean
} & StackProps

export const ErrorDisplay = ({
  errorDetail = (
    <Box>
      <chakra.h2 textStyle={{ base: "16-bold", md: "18-bold" }}>
        読み込みに失敗しました
      </chakra.h2>
      <chakra.p textStyle={{ base: "12-reg", md: "14-reg" }}>
        しばらくお待ちの上、再読み込みをお願い致します。
      </chakra.p>
    </Box>
  ),
  displayReloadButton = true,
  ...props
}: ErrorDisplayProps) => {
  const router = useRouter()

  return (
    <VStack
      spacing={3}
      px={{ base: 3, md: 0 }}
      py={3}
      w="full"
      maxW="container.content"
      mx="auto"
      {...props}
    >
      {errorDetail}
      {displayReloadButton && (
        <Button
          onClick={() => router.reload()}
          colorScheme="primary"
          size="sm"
          px={5}
        >
          再読み込み
        </Button>
      )}
    </VStack>
  )
}
