import {
  staticPath_assets_decoration_filled_star_svg,
  staticPath_assets_decoration_non_filled_star_svg,
} from "lib/$asset-path"
import {
  Center,
  chakra,
  HStack,
  HTMLChakraProps,
  Stack,
  StackProps,
  VStack,
  Wrap,
} from "@chakra-ui/react"
import { ErrorDisplay } from "@~/common/components/ErrorDisplay"
import { LoadingDisplay } from "@~/common/components/LoadingDisplay"
import { Tag } from "components/common-ui/Tag"
import { Layer, LayerProps } from "components/lp/Layer"
import { CategoryTagSection } from "components/review/CategoryTagSection"
import { useReview } from "components/review/hooks"
import { NextImage, NextImageInlineWrapper } from "components/system"
import NextLink, { LinkProps as NextLinkProps } from "next/link"
import { pagesPath } from "lib/$path"

import React, { PropsWithChildren } from "react"
import {
  CustomerReviewData,
  CustomerReviewTagWithAll,
  CUSTOMER_REVIEWER_IMG_MAPPING,
} from "./data"

export type CustomerReviewProps = {
  data: CustomerReviewData
} & StackProps

export const CustomerReview = ({ data, ...props }: CustomerReviewProps) => {
  const {
    age,
    gender,
    tags,
    satisfaction,
    consulting_reason,
    consulting_content,
    good_point,
  } = data

  return (
    <Stack
      spacing={{ base: 4, md: 12 }}
      direction={{ base: "column", md: "row" }}
      align={{ base: "initial", md: "flex-start" }}
      layerStyle="base"
      w="full"
      maxW="container.content"
      p={4}
      {...props}
    >
      <Meta age={age} gender={gender} tags={tags} />
      <Stack spacing={4}>
        <HStack spacing={2}>
          <Label>満足度</Label>
          <Satisfaction satisfaction={satisfaction} />
        </HStack>
        <Stack spacing={0}>
          <Label>相談した理由や背景</Label>
          <Content>{consulting_reason}</Content>
        </Stack>
        <Stack spacing={0}>
          <Label>マネイロコンシェルと相談した内容</Label>
          <Content>{consulting_content}</Content>
        </Stack>
        <Stack spacing={0}>
          <Label>良かった点</Label>
          <Content>{good_point}</Content>
        </Stack>
      </Stack>
    </Stack>
  )
}

export type CustomerReviewSectionProps = {
  category: CustomerReviewTagWithAll
  limit?: number
} & LayerProps

export const CustomerReviewSection = ({
  category,
  limit = 5,
  ...props
}: CustomerReviewSectionProps) => {
  const { reviews, error, selectedTagIds, onClickCategoryBtn } = useReview(
    category,
    limit
  )

  if (error) return <ErrorDisplay />
  if (!reviews) return <LoadingDisplay />

  return (
    <Layer
      titleLabel="体験レビュー・口コミ"
      id="customer-reviews-section"
      maxW="container.content"
      layerStyle="passive"
      px={3}
      py={{ base: 6, md: 12 }}
      {...props}
    >
      <CategoryTagSection
        selectedTagIds={selectedTagIds}
        onClickCategoryBtn={onClickCategoryBtn}
        mb={{ base: 4, md: 6 }}
      />
      <VStack spacing={4}>
        {reviews.map((data, idx) => {
          return (
            <CustomerReview data={data} key={`customer-review-${idx + 1}`} />
          )
        })}
      </VStack>
      <chakra.div textStyle="10-reg" my={4}>
        ※各口コミ内はお客さまの記載通りに掲載しています。
        <br />
        サービス・場所・人物など固有名称は、本来の定義と異なる場合もございます。閲覧される場合には十分ご留意ください。
      </chakra.div>
      <Center>
        <NextLink href={pagesPath.reviews.$url()} passHref legacyBehavior>
          <chakra.a
            textStyle="14-bold"
            _hover={{ textDecor: "underline", cursor: "pointer" }}
          >
            すべてのレビューを見る
          </chakra.a>
        </NextLink>
      </Center>
    </Layer>
  )
}

const Meta = ({
  age,
  gender,
  tags,
}: Pick<CustomerReviewData, "age" | "gender" | "tags">) => {
  const customerReviewImg =
    CUSTOMER_REVIEWER_IMG_MAPPING[gender][age ?? "default"] ??
    CUSTOMER_REVIEWER_IMG_MAPPING[gender].default

  return (
    <HStack
      spacing={4}
      align={{ base: "center", md: "flex-start" }}
      flexShrink={0}
      w={{ md: "170px" }}
    >
      <NextImageInlineWrapper>
        <NextImage alt="" {...customerReviewImg} />
      </NextImageInlineWrapper>
      <Stack spacing={2}>
        <Label>
          {age ? `${age}代：` : null}
          {gender === "male" ? "男性" : "女性"}
        </Label>
        <Wrap
          spacing={{ base: "3px", md: 2 }}
          direction={{ base: "row", md: "column" }}
        >
          {tags.map((tag) => {
            return (
              <Tag
                tagName={`#${tag.name}`}
                key={tag.id}
                textStyle="10-reg"
                px="8px"
                py="3px"
                fontSize={"10px"}
                fontWeight={"bold"}
                lineHeight="15px"
              />
            )
          })}
        </Wrap>
      </Stack>
    </HStack>
  )
}

export const Satisfaction = ({
  satisfaction,
}: Pick<CustomerReviewData, "satisfaction">) => {
  const MAX = 5 // 5段階評価
  const numberOfFilledStar = satisfaction
  const numberOfNonFilledStar = MAX - satisfaction

  return (
    <HStack spacing={1}>
      {[...Array(numberOfFilledStar)].map((_, idx) => {
        return (
          <NextImageInlineWrapper key={`filled-star-${idx}`}>
            <NextImage
              src={staticPath_assets_decoration_filled_star_svg}
              width={25}
              height={25}
              alt="星（塗りあり）"
              unoptimized
            />
          </NextImageInlineWrapper>
        )
      })}
      {[...Array(numberOfNonFilledStar)].map((_, idx) => {
        return (
          <NextImageInlineWrapper key={`non-filled-star-${idx}`}>
            <NextImage
              src={staticPath_assets_decoration_non_filled_star_svg}
              width={25}
              height={25}
              alt="星（塗りなし）"
              unoptimized
            />
          </NextImageInlineWrapper>
        )
      })}
    </HStack>
  )
}

export const Label = ({
  children,
  ...props
}: PropsWithChildren<{} & HTMLChakraProps<"div">>) => {
  return (
    <chakra.div textStyle="14-bold" {...props}>
      {children}
    </chakra.div>
  )
}

export const Content = ({
  children,
  ...props
}: PropsWithChildren<{} & HTMLChakraProps<"p">>) => {
  return (
    <chakra.p textStyle="12-reg" {...props}>
      {children}
    </chakra.p>
  )
}
