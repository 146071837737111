import { usePaginate } from "components/common-ui/Paginate"
import {
  CustomerReviewData,
  CustomerReviewTagWithAll,
} from "components/lp/consulting/CustomerReview/data"
import { calcLastPage, calcOffset } from "lib/cms/calculator"
import { getReviewList } from "pages/api/reviews/_index"
import { useState } from "react"
import useSWR from "swr"

export const useReview = (
  category: CustomerReviewTagWithAll = "all",
  limit: number = 10
) => {
  const [selectedTagIds, setSelectedTagIds] = useState<
    CustomerReviewTagWithAll[]
  >([category])
  const [offset, setOffset] = useState(0)

  const { data, error } = useSWR(
    ["getReviewList", selectedTagIds.join(","), offset, limit],
    getReviewList
  )
  const lastPage = data ? calcLastPage(data.totalCount, data.limit) : 1

  const { currentPage, pageList, setCurrentPage } = usePaginate(lastPage)

  const onClickCategoryBtn = (
    newSelectedTagIds: CustomerReviewTagWithAll[]
  ): void => {
    setSelectedTagIds(newSelectedTagIds)
    setOffset(0)
    setCurrentPage(1)
  }

  const onClickPaginateBtn = (newCurrentPage: number): void => {
    setCurrentPage(newCurrentPage)
    setOffset(calcOffset(newCurrentPage, data.limit))
    window.scrollTo(0, 0)
  }

  return {
    reviews: data ? translateCmsDataToReviewData(data.contents) : undefined,
    error,
    selectedTagIds,
    onClickCategoryBtn,
    currentPage,
    lastPage,
    pageList,
    onClickPaginateBtn,
  }
}

export const translateCmsDataToReviewData = (
  contents
): CustomerReviewData[] => {
  const reviews: CustomerReviewData[] = contents.map((content) => {
    return {
      age: content["age"],
      gender: content["gender"][0],
      tags: translateCmsTagsToTags(content["tags"]),
      satisfaction: parseInt(content["satisfaction"][0]),
      consulting_reason: content["consulting_reason"],
      consulting_content: content["consulting_content"],
      good_point: content["good_point"],
      answered_at: content["answered_at"],
      database_id: content["database_id"],
    }
  })
  return reviews
}

const translateCmsTagsToTags = (cmsTags) => {
  const tags = cmsTags.map((tag) => {
    return { id: tag.id, name: tag.name }
  })
  return tags
}
