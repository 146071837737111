import { Center, WrapItem, WrapItemProps } from "@chakra-ui/react"
import React from "react"

export type TagProps = {
  tagName: string
  onClick?: () => void
} & WrapItemProps

export const Tag = ({ tagName, onClick, ...props }: TagProps) => {
  return (
    <WrapItem
      color="white"
      bgColor="moneiro-color-main-orange"
      fontSize={{ base: "11px", md: "14px" }}
      px={{ base: 2, md: 3 }}
      py={1}
      borderRadius="25px"
      onClick={onClick}
      _hover={{
        cursor: "pointer",
      }}
      {...props}
    >
      <Center w="full">{tagName}</Center>
    </WrapItem>
  )
}

export const OutlineTag = ({ tagName, onClick, ...props }: TagProps) => {
  return (
    <Tag
      tagName={tagName}
      onClick={onClick}
      color="moneiro-color-main-orange"
      bgColor="white"
      border="solid 1px"
      borderColor="moneiro-color-main-orange"
      {...props}
    />
  )
}
