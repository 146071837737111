import { httpFetch } from "@~/common/lib/fetch"
import { compact } from "lib/loda"
import { stringify } from "querystring"

export async function getReviewList(
  _: unknown,
  tags: string,
  offset: number = 0,
  limit: number = 10
) {
  const req = compact({
    offset: offset,
    tags: tags !== "all" ? tags.split(",") : undefined,
    limit: limit,
  })

  return httpFetch(`/api/reviews?${stringify(req)}`, {
    method: "GET",
  }).then((r) => {
    return r.json()
  })
}
