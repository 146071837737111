import {
  chakra,
  HTMLChakraProps,
  Spinner,
  SpinnerProps,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import React from "react"

export type LoadingDisplayProps = {
  titleProps?: HTMLChakraProps<"h2">
  spinnerProps?: SpinnerProps
} & StackProps

export const LoadingDisplay = ({
  titleProps,
  spinnerProps,
  ...props
}: LoadingDisplayProps) => {
  return (
    <VStack
      spacing={3}
      px={{ base: 3, md: 0 }}
      py={3}
      w="full"
      maxW="container.content"
      mx="auto"
      {...props}
    >
      <chakra.h2 textStyle={{ base: "16-bold", md: "18-bold" }} {...titleProps}>
        読み込み中です…
      </chakra.h2>
      <Spinner
        emptyColor="moneiro-color-line-gray-basic"
        label="読み込み中です…"
        size="lg"
        speed="0.75s"
        thickness="3px"
        color="moneiro-color-main-orange"
        {...spinnerProps}
      />
    </VStack>
  )
}
