import { Center, Wrap, WrapProps } from "@chakra-ui/react"
import { OutlineTag, Tag, TagProps } from "components/common-ui/Tag"
import { CustomerReviewTagWithAll } from "components/lp/consulting/CustomerReview/data"
import { getReviewTagList } from "pages/api/review-tag/_index"
import { useCallback } from "react"
import useSWR from "swr"

type CategoryTagSectionProps = {
  selectedTagIds: CustomerReviewTagWithAll[]
  onClickCategoryBtn: (newSelectedTagIds: CustomerReviewTagWithAll[]) => void
} & WrapProps

export const CategoryTagSection = ({
  selectedTagIds,
  onClickCategoryBtn,
  ...props
}: CategoryTagSectionProps) => {
  const { data, error } = useSWR(["getReviewTagList"], getReviewTagList)

  const removeTag = useCallback(
    (tagId: CustomerReviewTagWithAll) => {
      if (selectedTagIds.length === 1) return onClickCategoryBtn(["all"])

      const newSelectedTagIds = selectedTagIds.filter((id) => id !== tagId)
      onClickCategoryBtn(newSelectedTagIds)
    },
    [onClickCategoryBtn, selectedTagIds]
  )

  const addTag = useCallback(
    (tagId: CustomerReviewTagWithAll) => {
      const allTag = selectedTagIds.find((tagId) => tagId === "all")
      // allタグが選択済みであれば外し、選択された新しいタグだけセットする
      if (allTag) return onClickCategoryBtn([tagId])

      onClickCategoryBtn([...selectedTagIds, tagId])
    },
    [onClickCategoryBtn, selectedTagIds]
  )

  const onClickTag = useCallback(
    (tagId, isSelected) => {
      // 選択済みだったら解除する
      isSelected ? removeTag(tagId) : addTag(tagId)
    },
    [addTag, removeTag]
  )

  return (
    <Center>
      <Wrap
        spacing={1}
        justify={`center`}
        maxW={{ base: "500px", md: "620px" }}
        {...props}
      >
        <CategoryTag
          tagName="ALL"
          key="all"
          onClick={() => onClickCategoryBtn(["all"])}
          isSelected={!!selectedTagIds.find((tagId) => tagId === "all")}
          w={{ base: 14, md: "55px" }}
        />
        {data &&
          data.contents.map((tag) => {
            const isSelected = !!selectedTagIds.find(
              (tagId) => tagId === tag.id
            )
            return (
              <CategoryTag
                tagName={`#${tag.name}`}
                key={tag.id}
                onClick={() => onClickTag(tag.id, isSelected)}
                isSelected={isSelected}
              />
            )
          })}
        <Tag
          tagName={"選択解除"}
          onClick={() => onClickCategoryBtn(["all"])}
          color="moneiro-color-main-orange"
          bgColor=""
        />
      </Wrap>
    </Center>
  )
}

const CategoryTag = ({
  tagName,
  onClick,
  isSelected,
  ...props
}: { isSelected: boolean } & TagProps) => {
  return isSelected ? (
    <Tag tagName={tagName} onClick={onClick} {...props} />
  ) : (
    <OutlineTag tagName={tagName} onClick={onClick} {...props} />
  )
}
