import {
  staticPath_assets_common_paginate_first_page_icon_svg,
  staticPath_assets_common_paginate_last_page_icon_svg,
  staticPath_assets_common_paginate_next_page_icon_svg,
  staticPath_assets_common_paginate_prev_page_icon_svg,
} from "lib/$asset-path"
import { Box, chakra, HStack, Img, StackProps } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"

export type PaginateProps = {
  /** 現在のページ番号(usePaginateで生成可能) */
  currentPage: number
  /** 最後のページ番号(usePaginateで生成可能) */
  lastPage: number
  /** ページ番号のリスト(usePaginateもしくはmakePageListで生成可能) */
  pageList?: number[]
  onPageChange: (page: number) => void
} & StackProps

export const Paginate = ({
  currentPage,
  lastPage,
  pageList = makePageList(currentPage, lastPage),
  onPageChange,
  ...props
}: PaginateProps) => {
  // ページ総数が1ページの場合は表示する必要がないのでnullを返す
  if (lastPage === 1) {
    return null
  }

  const irregular = lastPage === 2 || lastPage === 3
  const showPrevAndFirstIcons = !irregular && currentPage !== 1
  const showNextAndLastIcons = !irregular && currentPage !== lastPage

  return (
    <HStack spacing={5} {...props}>
      {showPrevAndFirstIcons ? (
        <>
          <Img
            onClick={() => onPageChange(1)}
            src={staticPath_assets_common_paginate_first_page_icon_svg}
            htmlWidth="18px"
            htmlHeight="19px"
            alt="最初のページへ"
            _hover={{
              cursor: "pointer",
            }}
          />
          <Img
            onClick={() => onPageChange(currentPage - 1)}
            src={staticPath_assets_common_paginate_prev_page_icon_svg}
            htmlWidth="9.5px"
            htmlHeight="19px"
            alt="一つ前のページへ"
            _hover={{
              cursor: "pointer",
            }}
          />
        </>
      ) : (
        <>
          <Box w="18px" h="19px" />
          <Box w="9.5px" h="19px" />
        </>
      )}
      {pageList.map((page) => {
        const isCurrent = page === currentPage

        return (
          <chakra.button
            onClick={() => onPageChange(page)}
            boxSize="37px"
            bgColor={isCurrent ? "moneiro-color-main-orange" : "white"}
            textStyle="14-bold"
            color={isCurrent ? "white" : "#000"}
            border={isCurrent ? undefined : "1px solid #000"}
            borderRadius="5px"
            key={`page-button-${page}`}
          >
            {page}
          </chakra.button>
        )
      })}
      {showNextAndLastIcons ? (
        <>
          <Img
            onClick={() => onPageChange(currentPage + 1)}
            src={staticPath_assets_common_paginate_next_page_icon_svg}
            htmlWidth="9.5px"
            htmlHeight="19px"
            alt="一つ次のページへ"
            _hover={{
              cursor: "pointer",
            }}
          />
          <Img
            onClick={() => onPageChange(lastPage)}
            src={staticPath_assets_common_paginate_last_page_icon_svg}
            htmlWidth="18px"
            htmlHeight="19px"
            alt="最後のページへ"
            _hover={{
              cursor: "pointer",
            }}
          />
        </>
      ) : (
        <>
          <Box w="9.5px" h="19px" />
          <Box w="18px" h="19px" />
        </>
      )}
    </HStack>
  )
}

export const makePageList = (currentPage: number, lastPage: number) => {
  if (lastPage === 1) {
    return [1]
  } else if (lastPage === 2) {
    return [1, 2]
  } else if (lastPage === 3) {
    return [1, 2, 3]
  }

  const diff = lastPage - currentPage
  if (diff === 1) {
    return [currentPage - 1, currentPage, currentPage + 1]
  } else if (diff === 0) {
    return [currentPage - 2, currentPage - 1, currentPage]
  } else {
    return [currentPage, currentPage + 1, currentPage + 2]
  }
}

export const usePaginate = (
  lastPage: number,
  initialPage?: number
): Pick<PaginateProps, "currentPage" | "lastPage" | "pageList"> & {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
} => {
  const [currentPage, setCurrentPage] = useState(initialPage ?? 1)

  const pageList = useMemo(() => {
    return makePageList(currentPage, lastPage)
  }, [currentPage, lastPage])

  return {
    currentPage,
    lastPage,
    pageList,
    setCurrentPage,
  }
}
