import {
  staticPath_assets_illusts_user_man_30_main_svg,
  staticPath_assets_illusts_user_woman_30_main_svg,
  staticPath_assets_illusts_user_woman_40_main_svg,
  staticPath_assets_illusts_user_woman_50_main_svg,
} from "lib/$asset-path"
import { NextImageProps } from "components/system"

import React from "react"

export const CUSTOMER_REVIEW_TAGS = [
  {
    id: "future-fund",
    name: "将来資金",
  },
  {
    id: "edu-fund",
    name: "教育資金",
  },
  {
    id: "tsumitate-nisa",
    name: "つみたてNISA",
  },
  {
    id: "ideco",
    name: "iDeCo",
  },
  {
    id: "fund",
    name: "投資信託",
  },
  {
    id: "insurance-review",
    name: "保険の見直し",
  },
] as const

export type CustomerReviewTag = typeof CUSTOMER_REVIEW_TAGS[number]
export type CustomerReviewTagWithAll = CustomerReviewTag["id"] | "all"

const findCustomerReviewTag = (
  id: CustomerReviewTag["id"]
): CustomerReviewTag => {
  return CUSTOMER_REVIEW_TAGS.find((tag) => tag.id === id)!
}

export type CustomerReviewData = {
  id?: string
  age: number | null
  gender: "male" | "female"
  tags: CustomerReviewTag[]
  satisfaction: 0 | 1 | 2 | 3 | 4 | 5
  consulting_reason: React.ReactNode
  consulting_content: React.ReactNode
  good_point: React.ReactNode
  answered_at: string
  database_id: number
}

export const CUSTOMER_REVIEWER_IMG_MAPPING: Record<
  CustomerReviewData["gender"],
  Record<number | "default", NextImageProps>
> = {
  male: {
    30: {
      src: staticPath_assets_illusts_user_man_30_main_svg,
      width: 52,
      height: 60,
      alt: "30代男性",
    },
    default: {
      src: staticPath_assets_illusts_user_man_30_main_svg,
      width: 52,
      height: 60,
      alt: "男性",
    },
  },
  female: {
    30: {
      src: staticPath_assets_illusts_user_woman_30_main_svg,
      width: 47,
      height: 69,
      alt: "30代女性",
    },
    40: {
      src: staticPath_assets_illusts_user_woman_40_main_svg,
      width: 51,
      height: 62,
      alt: "40代女性",
    },
    50: {
      src: staticPath_assets_illusts_user_woman_50_main_svg,
      width: 52,
      height: 64,
      alt: "50代女性",
    },
    default: {
      src: staticPath_assets_illusts_user_woman_30_main_svg,
      width: 47,
      height: 69,
      alt: "女性",
    },
  },
} as const

// storybook用のデータです。
export const CUSTOMER_REVIEW_DATA: CustomerReviewData[] = [
  {
    age: 30,
    gender: "female",
    tags: [
      findCustomerReviewTag("edu-fund"),
      findCustomerReviewTag("future-fund"),
      findCustomerReviewTag("insurance-review"),
    ],
    satisfaction: 5,
    consulting_reason:
      "子供の教育資金、老後の資金について相談したいと思っていた為。",
    consulting_content: (
      <>
        教育資金、老後資金、医療保険について。
        <br />
        資産運用について詳しく説明していただいた。
      </>
    ),
    good_point: "細かく、初心者向けに話をしていただけたのでわかりやすかった。",
    answered_at: "2022-02-28T06:15:54.092Z",
    database_id: 1,
  },
  {
    age: 50,
    gender: "female",
    tags: [
      findCustomerReviewTag("insurance-review"),
      findCustomerReviewTag("fund"),
    ],
    satisfaction: 4,
    consulting_reason: "自分に合った投資信託の商品を知りたかった。",
    consulting_content:
      "加入している保険や投資信託の内容を見て、次にどのような商品を選んだら良いか",
    good_point:
      "全体を見て、何が必要ないかを教えてくれて、おすすめの商品を提案してくれた。",
    answered_at: "2022-02-28T06:15:54.092Z",
    database_id: 2,
  },
  {
    age: 40,
    gender: "female",
    tags: [
      findCustomerReviewTag("insurance-review"),
      findCustomerReviewTag("future-fund"),
    ],
    satisfaction: 5,
    consulting_reason: "老後資金の確保のため資産運用について知りたかった",
    consulting_content:
      "自分の状況や目標に見合った老後資金の増やし方、保険について",
    good_point: "わかりやすく自分に合った資金調達の仕方を教えていただいた",
    answered_at: "2022-02-28T06:15:54.092Z",
    database_id: 3,
  },
  {
    age: 30,
    gender: "female",
    tags: [
      findCustomerReviewTag("tsumitate-nisa"),
      findCustomerReviewTag("insurance-review"),
    ],
    satisfaction: 4,
    consulting_reason: "老後資金について、今できる投資などを知りたかった",
    consulting_content: "積み立てNISAと変額保険の違いについて",
    good_point: "表などを利用し、比較点が見やすかった",
    answered_at: "2022-02-28T06:15:54.092Z",
    database_id: 4,
  },
  {
    age: null,
    gender: "male",
    tags: [
      findCustomerReviewTag("insurance-review"),
      findCustomerReviewTag("fund"),
    ],
    satisfaction: 4,
    consulting_reason:
      "投資信託を考えだした時に、ニーサやイデコの事を教えてくれるオンライン講座を受講して個別相談させていただきました",
    consulting_content: (
      <>
        保険会社を利用しての変額保険や自分で投資信託をする場合等のメリット、デメリット
        <br />
        どの保険会社が自分に適しているか
      </>
    ),
    good_point:
      "投資のミルフィーユの考え方を教わったり、スライドや説明がすごくわかりやすかったです。",
    answered_at: "2022-02-28T06:15:54.092Z",
    database_id: 5,
  },
]
